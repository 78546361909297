<template>
  <div class="practice-new">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>课程管理</BreadcrumbItem>
      <BreadcrumbItem>课程列表</BreadcrumbItem>
      <BreadcrumbItem>课程详情</BreadcrumbItem>
      <BreadcrumbItem>编辑练习</BreadcrumbItem>
    </Breadcrumb>

    <div class="content">
      <div class="title">
        <span>编辑练习</span>
        <div class="question-opt">
          <Button size="small" @click="handleCloseWindow" style="font-size: 14px">取消</Button>
        </div>
      </div>

      <CommonPractice ref="practiceForm" :is-add="false" :course-id="courseId" v-model="practice"></CommonPractice>

      <div class="submit-container">
        <Button type="default" :loading="saveLoading" @click="handleSavePractice" :disabled="!practice.question.length">仅保存</Button>
        <Button
          type="primary"
          :loading="releaseLoading"
          @click="handleReleasePractice"
          :disabled="!practice.question.length"
          style="margin-left: 20px"
          >发布练习
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import courseApi from '@api/course'
import examApi from '@api/exam'
import { PRACTICE_STATUS } from '@/util/practiceStatus'
import CommonPractice from '@/components/manage/course/practice/CommonPractice'

export default {
  components: {
    CommonPractice
  },
  data: function() {
    return {
      chapters: [],
      saveLoading: false,
      releaseLoading: false,
      practice: {
        practiceId: -1,
        sectionId: [],
        name: '',
        createTime: '',
        endTime: '',
        question: []
      }
    }
  },
  mounted() {
    this.$Loading.start()
    examApi
      .getPracticeDetail(this.practiceId)
      .then(res => {
        const data = res.res
        //题目需要按 order 排序，后端返回顺序不确定
        data.question = data.question
          .sort((a, b) => {
            return a.order - b.order
          })
          .map(question => {
            question.questionId = question.id
            return question
          })
        data.endTime = data.endTime ? new Date(data.endTime) : ''
        this.practice = data
      })
      .finally(() => {
        this.$Loading.finish()
      })
  },
  methods: {
    handleCloseWindow() {
      window.close()
    },
    handleSavePractice() {
      this.$refs.practiceForm.validate(valid => {
        if (!valid) {
          return
        }

        this.saveLoading = true
        this.updatePractice(PRACTICE_STATUS.EDIT.value)
          .then(() => {
            this.$message.success('保存成功')
            window.close()
          })
          .finally(() => {
            this.saveLoading = false
          })
      })
    },
    handleReleasePractice() {
      this.$refs.practiceForm.validate(valid => {
        if (!valid) {
          return
        }

        this.releaseLoading = true
        this.updatePractice(PRACTICE_STATUS.PUBLISH.value)
          .then(() => {
            this.$message.success('发布成功')
            window.close()
          })
          .finally(() => {
            this.releaseLoading = false
          })
      })
    },
    updatePractice(status) {
      return examApi.updatePractice({
        practiceId: this.practice.practiceId,
        createTime: this.practice.createTime,
        endTime: this.practice.endTime && this.practice.endTime.valueOf(),
        name: this.practice.name,
        status: status,
        questionIds: this.practice.question.map(question => question.questionId)
      })
    }
  },
  computed: {
    courseId() {
      return Number(this.$route.params.courseId)
    },
    practiceId() {
      return Number(this.$route.params.practiceId)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables.less';

.practice-new {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  background-color: @layout-body-background;
  overflow: auto;
  text-align: left;

  .content {
    background-color: white;
    box-shadow: 0px 0px 3px 0px rgba(7, 27, 41, 0.1);
    margin-top: 10px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: #e4e9ef;
      font-size: 16px;
      color: @font-color-content;
    }

    .submit-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: #e4e9ef;

      .ivu-btn {
        font-size: 14px;
        padding: 2px 12px;
      }
    }
  }
}
</style>
